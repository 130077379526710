/* Tables */

table {
    width: 100%;
    max-width: 100%;
}

table>caption+thead>tr:first-child>th, table>caption+thead>tr:first-child>td, table>colgroup+thead>tr:first-child>th, table>colgroup+thead>tr:first-child>td, table>thead:first-child>tr:first-child>th, table>thead:first-child>tr:first-child>td {
    border-top: 0;
}

table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 1px solid $grey;
}

table>thead>tr>th, table>thead>tr>td {
    border-bottom-width: 2px !important;
}

table>thead>tr>th, table>thead>tr>td, table>tbody>tr>th, table>tbody>tr>td, table>tfoot>tr>th, table>tr>td {
    padding: 15px;
    line-height: 1.5;
    vertical-align: top;
    border-bottom: 1px solid $light-grey;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: $bg-color !important;
}

/* / End Tables */