/* Progress Bars */

.progress {
    height: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: $light-grey;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.progress-bar {
    font-size: 12px;
    line-height: 20px;
    color: $white;
    background-color: $primary;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
}

.progress-bar-default {
    background-color: $default;
}

.progress-bar-primary {
    background-color: $primary;
}

.progress-bar-success {
    background-color: $success;
}

.progress-bar-info {
    background-color: $info;
}

.progress-bar-warning {
    background-color: $warning;
}

.progress-bar-danger {
    background-color: $danger;
}

.progress-label .sr-only {
    display: none;
}

.progress-bar span {
    border-radius: 15px;
    display: inline-block;
    height: 24px;
    letter-spacing: 0;
    line-height: 23px;
    min-width: 24px;
    padding: 0 3px;
    position: absolute;
    right: -5px;
    text-align: center;
    bottom: 0;
}

/* / End Progress Bars */