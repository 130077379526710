// Font and Styles

$primary-font: 'Raleway', sans-serif;
$regular: 400;
$bold: 700;
$xbold: 800;
$xxbold: 900;
$letter-spacing: 0.05em;
$letter-spacing-2x: 0.1em;

// Theme Colors

$default: #333;
$default-hover: #111;
$primary: #cc0000;
$primary-hover: #cc0000;
$spotify: #1FCE61;
$ggl: #FFCD32;
$success: #93bc49;
$success-hover: #7ea03f;
$info: #54bedc;
$info-hover: #3a9fbc;
$warning: #e2a740;
$warning-hover: #cb9538;
$danger: #de3f3f;
$danger-hover: #bf3535;
$title-color: #111;
$text-color: #666;
$bg-color: #f8f8f8;
$dark-bg-color: #222;
$body-bg: #fefefe;
$white: #fff;
$white-transparent: rgba(255,255,255,0.75);
$light-grey: #ebebeb;
$grey: #ccc;
$mid-grey: #999;
$dark-grey: #333;
$black: #000;
$black-transparent: rgba(0,0,0,0.75);
$primary-transparent: rgba(195,157,109,0.75);

// Social Colors

$facebook: #4e68a1;
$facebook-hover: #3b4f7a;
$twitter: #65b5f2;
$twitter-hover: #5294c6;
$google: #e05d4b;
$google-hover: #b94c3d;
$linkedin: #2083bc;
$linkedin-hover: #186592;
$pinterest: #d2373b;
$pinterest-hover: #ad2c2f;
$dribbble: #ec5f94;
$dribbble-hover: #b4446e;