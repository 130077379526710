/* Listgroups & Panels */

/* Listgroups */

.list-group-item {
    color: $text-color;
    background-color: $white;
    font-size: 14px;
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
}

.list-group-item p {
    font-size: 14px;
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
    color: $white;
    background-color: $primary;
    border-color: $primary;
}

a.list-group-item:hover {
    color: $primary;
}

a.list-group-item, button.list-group-item {
    color: $text-color;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:focus .list-group-item-text, .list-group-item.active:hover .list-group-item-text {
    color: $white;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
    color: $title-color;
}

/* / End Listgroups */

/* Panels */

.panel-title {
    font-size: 16px;
    font-weight: $regular;
    letter-spacing: $letter-spacing;
}

.panel-default {
    border-color: $default-hover;
}

.panel-default > .panel-heading {
    color: $white;
    background-color: $default;
    border-color: $default-hover;
}

.panel-success {
    border-color: $success-hover;
}

.panel-success > .panel-heading {
    color: $white;
    background-color: $success;
    border-color: $success-hover;
}

.panel-primary {
    border-color: $primary-hover;
}

.panel-primary > .panel-heading {
    color: $white;
    background-color: $primary;
    border-color: $primary-hover;
}

.panel-info {
    border-color: $info-hover;
}

.panel-info > .panel-heading {
    color: $white;
    background-color: $info;
    border-color: $info-hover;
}

.panel-warning {
    border-color: $warning-hover;
}

.panel-warning > .panel-heading {
    color: $white;
    background-color: $warning;
    border-color: $warning-hover;
}

.panel-danger {
    border-color: $danger-hover;
}

.panel-danger > .panel-heading {
    color: $white;
    background-color: $danger;
    border-color: $danger-hover;
}

/* / End Panels */

/* Well */

.well {
    min-height: 20px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: $light-grey;
    border: 1px solid $grey;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.rounded-well {
    border-radius: 5px;
}

/* / End Well */

/* Jumbotron */

.jumbotron {
    background: $light-grey;
    border: 1px solid $grey;
    border-radius: 0 !important;
}

.rounded-jumbotron {
    border-radius: 5px !important;
}

.jumbotron h1 {
    color: $primary;
    font-weight: $regular;
    margin-top: 0;
    margin-bottom: 25px;
}

.jumbotron p {
    font-size: 18px;
    font-weight: $regular;
    margin-bottom: 25px;
}

/* / End Jumbotron */

/* / End Listgroups & Panels */