/* Tabs & Accordions */

/* Tabs */

.nav-tabs {
    border-bottom: 3px solid $light-grey;
}

.nav-tabs > li {
    float: none;
    margin-bottom: -3px;
    display: inline-block;
}

.nav-tabs>li>a {
    position: relative;
    z-index: 0;
    display: block;
    color: $text-color;
    font-size: 12px;
    text-decoration: none;
    font-weight: $bold;
    margin-right: 0;
    padding: 20px;
    line-height: 1.5;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
}

.nav-tabs > li > a:focus {
    background: transparent;
    border: 0 !important;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: $primary;
    background: none;
    border: none;
    border-bottom: 3px solid $primary !important;
    -webkit-transition: 0s;
    -moz-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}

.nav > li > a:hover {
    background: none;
    border: none;
}

.nav-tabs > li {
    float: none;
    margin-bottom: -3px;
    display: inline-block;
}

.nav-pills.text-right>li {
    float: none;
    display: inline-block;
}

.nav.nav-pills a:hover {
    color: $white;
    background: $primary-hover;
}

.tab-content {
    padding-top: 30px;
}

/* / End Tabs */

/* Accordions */

.panel-group {
    margin-bottom: 10px;
}

.panel-group .panel {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
}

.panel-group .panel-heading {
    padding: 10px 0;
    border: none;
    border-radius: 0;
}

.panel-group .panel-title {
    font-size: 14px;
    font-weight: $regular;
}

.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: none;
}

.panel-group .panel-heading>a:after {
    display: inline-block;
    vertical-align: middle;
    font-family: "FontAwesome";
    font-size: 10px;
    content: "\f068";
    margin-top: -2px;
    margin-left: 10px;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.panel-group .panel-heading .panel-title.collapsed:after {
    content: "\f067";
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.panel-group .panel-body {
    padding: 15px 0;
}

.panel-group.tabbed .panel-heading{
    background: $light-grey;
    padding: 10px 15px;
}

.panel-group.tabbed a {
    color: $text-color;
}

.panel-group.tabbed .panel-heading>a:after {
    float: right;
}

.panel-group.tabbed .panel-heading .panel-title.collapsed:after {
    float: right;
}

/* / End Accordions */

/* / End Tabs & Accordions */